<template>
  <div class="home">
    <div class="cover">
      <img class="logo-large" src="https://dinolloss.event.com.cn/EOY2023/assets/slogan.png">
      <div class="content">
        <div @click="onHandleMenu(true, '/eoy')" class="menu-item">
          <img src="https://dinolloss.event.com.cn/EOY2023/assets/index-menu1.png">
          <!-- <span>荣誉绽放</span> -->
        </div>
        <div @click="onHandleMenu(true, '/active')" class="menu-item">
          <img src="https://dinolloss.event.com.cn/EOY2023/assets/index-menu2.png">
          <!-- <span>蓉城日历</span> -->
        </div>
        <!-- @click="onHandleMenu(false, 'https://www.pailixiang.com/m/album_ia4825980042.html')" -->
        <div @click="onHandleMenu(false, 'https://www.pailixiang.com/album_ia6359153217.html')" class="menu-item">
          <img src="https://dinolloss.event.com.cn/EOY2023/assets/index-menu3.png">
          <!-- <span>蓉城掠影</span> -->
        </div>
        <div @click="onHandleMenu(true, '/mark')" class="menu-item">
          <img src="https://dinolloss.event.com.cn/EOY2023/assets/index-menu4.png">
          <!-- <span>蓉城印记</span> -->
        </div>
        <div @click="onHandleMenu(true, '/live')" class="menu-item">
        <!-- <div @click="onHandleLive" class="menu-item"> -->
          <img src="https://dinolloss.event.com.cn/EOY2023/assets/index-menu5.png">
          <!-- <span>蓉城直播</span> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// import * as router from 'vue-router';
// console.log(router);
import md5 from 'js-md5';
import fetch from '../utils/fetch';
import { getSign } from '@/utils/sign';
import { useRouter } from 'vue-router';
const Router = useRouter();

/**
 * 获取用户信息
 */
let userInfo = JSON.parse(localStorage.getItem('userInfo_n')); //获取本地存储的登录信息
if (userInfo) {
  // console.log(userInfo.userid, userInfo.name, userInfo.avatar);
  var key = md5(userInfo.userid + 'dinoll');
}

// 添加日志
const addLog = (type) => {
  let params = {
    userid: userInfo.userid,
    type: type,
  }
  let { nowTime, token, Sign } = getSign(params)
  Sign.then((res) => {
    if (res.code == 200) {
      fetch.post('/addYeLog', {
        userid: userInfo.userid,
        type: type,
        token: token,
        timestemp: nowTime,
        sign: res.data
      }).then(_ => _).catch(_ => _)
    }
  })
}
/**
 * 页面跳转
 * @param type (true内部 false外部)
 * @param url 地址
 */
const onHandleMenu = (type, url) => {
  if (type) {
    Router.push(url);
  } else {
    addLog(8)
    location.href = url
  }
};

/**
 * 跳转到直播页面
 */
const onHandleLive = () => {
  addLog(9)
  // console.log(userInfo.userid,userInfo.name,key,userInfo.avatar);
  let params = {
    outerid: userInfo.userid,
    username: userInfo.name,
    hash_act_id: 'mv6v376l',
    key: key,
    user_avatar: userInfo.avatar
  }
  let { nowTime, token, Sign } = getSign(params)
  Sign.then((res) => {
    if (res.code == 200) {
      location.href = `https://eoy2022.5idhl.com/DHL/public/index.php/jumpMudu?outerid=${userInfo.userid}&username=${userInfo.name}&hash_act_id=mv6v376l&key=${key}&user_avatar=${userInfo.avatar}&token=${token}&timestemp=${nowTime}&sign=${res.data}`;
      // location.href = `https://eoy2022.5idhl.com/public/index.php/jumpMudu?outerid=${userInfo.userid}&username=${userInfo.name}&hash_act_id=lr3458pl&key=${key}&user_avatar=${userInfo.avatar}`;
    }
  })

};
</script>
<style lang="scss" scoped>
// 最大宽度600px-手机
// @media screen and (max-width: 600px) {
@font-face {
  font-family: my_del;
  src: url('../assets/Delivery_A_Bd.ttf');
}

.cover {
  width: 100vw;
  min-height: 100vh;
  background-image: url('https://dinolloss.event.com.cn/EOY2023/assets/index-bg.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;

  .logo-large {
    width: 73.067vw;
    margin-top: 23.467vw;
    margin-bottom: 6.667vw;
  }

  .content {
    width: 76.8vw;
    margin: 0 auto;
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;

    // border: 1px solid red;
    .menu-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: -1vw;

      span {
        font-family: my_del;
        font-size: 2.6vw;
        // font-weight: 600;
        color: #fff;
        font-family: my_del;
      }
    }

    img {
      width: 100%;
      // width: 19.8148vw;
      // margin-top: 14.537vw;
    }
  }
}

// }
// 最小宽度600px-电脑
// @media screen and (min-width: 600px) {
//   .cover {
//     width: 100vw;
//     height: 100vh;
//     background-color: rgb(0, 188, 226);
//   }
// }</style>