import axios from 'axios'

axios.defaults.baseURL = 'https://eoy2022.5idhl.com/DHL/public/index.php';
// axios.defaults.baseURL = 'https://dhl.event.com.cn/DHL/public/index.php';
// axios.defaults.baseURL = 'https://eoy2022.5idhl.com/DHL/public/index.php';

axios.defaults.timeout = 10000;
axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
// axios.defaults.headers["Content-Type"] = "multipart/form-data";
// axios.defaults.headers["Content-Type"] = "application/json";

axios.interceptors.request.use(function (config) {
  return config;
}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
  return response.data;
}, function (error) {
  return error.response.data;
});

export default axios;