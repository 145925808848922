import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import VC from 'vconsole'

import { Button, Tab, Tabs, Lazyload } from 'vant';
import 'vant/lib/index.css';

// new VC();

const app = createApp(App)
app.use(router).mount('#app')

app.use(Button).use(Tab).use(Tabs).use(Lazyload, {
    lazyComponent: true,
});
