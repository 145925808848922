import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import fetch from '../utils/fetch';
import { getSign } from '@/utils/sign';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/eoy',
    name: 'eoy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/eoy.vue')
  },
  {
    path: '/active',
    name: 'active',
    component: () => import('../views/active.vue')
  },
  {
    path: '/city',
    name: 'city',
    component: () => import('../views/city.vue')
  },
  {
    path: '/city_list',
    name: 'city_list',
    component: () => import('../views/city_list.vue')
  },
  {
    path: '/city_list12',
    name: 'city_list12',
    component: () => import('../views/city_list12.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/mark',
    name: 'mark',
    component: () => import('../views/mark.vue')
  }
  ,
  {
    path: '/play',
    name: 'Player',
    component: () => import('../components/Player.vue') 
  },
  {
    path: '/lueying',
    name: 'lueying',
    component: () => import('../views/lueying.vue') 
  },
  {
    path: '/live',
    name: 'live',
    component: () => import('../views/live.vue') 
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const userInfo = localStorage.getItem('userInfo_n'); //获取本地存储的登陆信息
  // if(userInfo){
  //   console.log(JSON.parse(userInfo).name,"登录");
  // }
  // console.log(userInfo.userid,"登录id");
  // const userId = localStorage.getItem('userId'); 
  if (to.name == 'login') {
    // 如果已经登陆进入首页
    if (userInfo) {
      next({ path: '/' })
    } else {
      next();
    }
  } else {
    if (userInfo) {
      // console.log(to.path)
      let type = ''
      switch (to.path) {
        case '/':
          type = 1;
          break;
        case '/eoy':
          type = 2;
          break;
        case '/city':
          type = 3;
          break;
        case '/active':
          type = 4;
          break;
          case '/mark':
            type = 10;
            break;
      }
      if (type) {
        let params = {
          userid: JSON.parse(userInfo).userid,
          type: type,
        }
        let { nowTime, token, Sign } = getSign(params)
        Sign.then((res) => {
          if (res.code == 200) {
            fetch.post('/addYeLog', {
              userid: JSON.parse(userInfo).userid,
              type: type,
              token: token,
              timestemp: nowTime,
              sign: res.data
            }).then(_ => _).catch(_ => _)
          }
        })
      }
      next();
    } else {
      next({
        name: 'login',
        replace: true
      })
    }
  }
})

export default router
