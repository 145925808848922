<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view />
</template>
<script setup>
import { onMounted, ref } from 'vue';


let imgList = ref([
  'https://dinolloss.event.com.cn/EOY2023/assets/a-1-bg.jpg',
  'https://dinolloss.event.com.cn/EOY2023/assets/a-1-main.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/a-1-title.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/a-2-main.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/a-2-title.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/a-3-main.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/a-3-title.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/a-bg.jpg',
  'https://dinolloss.event.com.cn/EOY2023/assets/active-bear.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/active-item1.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/active-item2.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/active-item3.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/active-title.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/area-1.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/area-10.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/area-11.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/area-12.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/area-2.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/area-3.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/area-4.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/area-5.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/area-6.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/area-7.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/area-8.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/area-9.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/area-bg.jpg',
  'https://dinolloss.event.com.cn/EOY2023/assets/area-cover.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/area-lu-car.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/back.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/close.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/index-bg.jpg',
  'https://dinolloss.event.com.cn/EOY2023/assets/index-menu1.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/index-menu2.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/index-menu3.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/index-menu4.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/index-menu5.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/list-bg.jpg',
  'https://dinolloss.event.com.cn/EOY2023/assets/list-guang-bg.jpg',
  'https://dinolloss.event.com.cn/EOY2023/assets/live-clock.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/live-postion.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/logo.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/mark-banner.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/slogan.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/heart-solid.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/heart.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/lue.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/area-cover.gif',
  'https://dinolloss.event.com.cn/EOY2023/assets/live-banner.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/live-btn1.png',
  'https://dinolloss.event.com.cn/EOY2023/assets/live-btn2.png'
]);

const load = (imgSrc) => {
  var imgs = [];
  for (let i = 0; i <= imgSrc.length; i++) {
    imgs[i] = new Image();
    imgs[i].src = imgSrc[i];
    imgs[i].onload = function () {
      this.style.display = 'none';
      document.body.appendChild(this);
    };
  }
  return imgs;
};

// 企业微信分享

onMounted(()=>{
  load(imgList.value);
})
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
.home {
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
}
img {
  vertical-align: middle;
}
.back {
  position: fixed;
  top: 4vw;
  left: 6vw;
  width: 4.6296vw;
  z-index: 999;
}
.logo {
  position: absolute;
  top: 4.5vw;
  right: 5vw;
  width: 24.2222vw;
}
body {
  font-family: my_del;
}
@font-face {
  font-family: my_del;
  src: url('./assets/Delivery_A_Bd.ttf');
}
</style>
