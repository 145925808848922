import fetch from './fetch'
import md5 from 'js-md5';

function getSign(params) {
  // 获取当前时间（时间戳）
  let nowTime = new Date().getTime()
  nowTime = String(nowTime).slice(0, -3)
  let token = md5(nowTime + 'DHL2023');

  let signature = fetch.post('/getSign',{
    ...params,
      token: token,
      timestemp: nowTime
  } )
  return {
    nowTime: nowTime,
    token: token,
    Sign: signature
  }
}

export { getSign }
